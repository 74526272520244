import { db2 } from '../Database/Series1';
import { collection, getDocs } from 'firebase/firestore';

const FetchSeries = async () => {
    try {
        const moviesCollection = collection(db2, 'seriesContent');
        const movieSnapshot = await getDocs(moviesCollection);
        const movieList = movieSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return movieList;
        
    } catch (error) {
        console.error("Error fetching movies:", error);
        return [];
    }
};

export default FetchSeries;
