import './Home.css'
import React, { useEffect, useState } from 'react'
import FetchMovies from '../../fetching/FetchMovie';
import FetchSeries from '../../fetching/FetchSeries';
import { Link } from 'react-router-dom';

const Home = () => {
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);
  useEffect(() => {
   

    const getMovies = async () => {
      const movieArray = await FetchMovies();
      setMovies(movieArray);
    };
    const getSeries = async () => {
      const seriesArray = await FetchSeries();
     
      setSeries(seriesArray)
    }

    getMovies();
    getSeries();

    shuffleArray(movies)
  }, []);
  

  return (
    <div className='home'>

      <div className="maiwe">
        {movies.slice(0, 1).map(movie => (
          <div className='single-movie' key={movie.id}>
            <img src={movie.imageURL} alt="" />
            <div className="right-single">


              <div className="wrt-cont-mg">
                <div className="title-wrt">
                  <h1>{movie.titleEng}</h1>
                  <h2>{movie.titleEngJap}</h2>
                </div>
                <div className="des-wrt">
                  <div className="hid">
                    <p>{movie.description}</p>
                  </div><div className="ant-wrttt">
                    <div className="ant1 aat">
                      <p>Movie</p>
                    </div>
                    <div className="ant2 aat">
                      <p>HD</p>
                    </div>
                    <div className="ant3 aat">
                      <span>2023</span>
                    </div>
                  </div>
                </div>
                <div className="btns-wrt">
                  <button type="button" >
                    <Link to={`/detail/movie/${movie.id}`}>View Now</Link>
                  </button>
                  <button type="button" onClick={() => {alert("We are working on this...")}}>+ Add To Watch</button></div>
              </div>
            </div>
          </div>
        ))}
      </div>


      <div className="Movies">
        <div className="heading">
          <h1>Anime Movie</h1>
          <hr /> <br />

        </div>
        <div className="animesMovie">
          {shuffleArray(movies).slice(0, 10).reverse().map((movie) => (
            <Link to={`/detail/movie/${movie.id}`} key={movie.id} className="outer-box">
              <div className="innerbox">
                <img src={movie.imageURL} alt={`${movie.titleEng} Poster`} className="movie-poster" />
              </div>
              <p>{movie.titleEng}</p>
            </Link>
          ))}
        </div>
        <br /><br /><br />
      </div>


      <div className="Series">
        <div className="heading">
          <h1>Anime Shows</h1>
          <hr /><br />
        </div>

        <div className="animesMovie">
          {shuffleArray(series).slice(0, 10).reverse().map((series) => (
            <Link to={`/detail/series/${series.id}`} key={series.id} className="outer-box">
              <div className="innerbox">
                <img src={series.imageURL} alt={`${series.titleEng} Poster`} className="movie-poster" />
              </div>
              <p>{series.titleEng}</p>
            </Link>
          ))}
        </div>
      </div>

    </div>
  )
}

export default Home