
// FirebaseMovie1.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {getAuth} from 'firebase/auth'

const firebaseConfig3 = {
    apiKey: "AIzaSyAchWV6hyalVLq6cwClq1RrBT-e6F4Xlg4",
  authDomain: "anime1manga-a83b7.firebaseapp.com",
  projectId: "anime1manga-a83b7",
  storageBucket: "anime1manga-a83b7.appspot.com",
  messagingSenderId: "835888121191",
  appId: "1:835888121191:web:eb53650607ef71012b9bc2"
};

const app3 = initializeApp(firebaseConfig3, "app3");
const db3= getFirestore(app3);
const storage3 = getStorage(app3);
const auth = getAuth(app3)

export { db3, storage3, auth };

