import { db1 } from '../Database/Movie1';
import { collection, getDocs } from 'firebase/firestore';

const FetchMovies = async () => {
    try {
        const moviesCollection = collection(db1, 'movieContent');
        const movieSnapshot = await getDocs(moviesCollection);
        const movieList = movieSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return movieList;
        
    } catch (error) {
        console.error("Error fetching movies:", error);
        return [];
    }
};

export default FetchMovies;
