import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import FetchMovies from '../../fetching/FetchMovie';
import FetchSeries from '../../fetching/FetchSeries';
import './Watch.css';

const SeriesStream = () => {
    const { id, epid, type } = useParams(); // Access id and epid from the URL
    const episodeIndex = parseInt(epid, 10); // Convert epid to a number for indexing

    const [content, setContent] = useState(null); // For storing movie or series data
    const [videoURL, setVideoURL] = useState(null); // For the video URL

    useEffect(() => {
        const fetchContent = async () => {
            setVideoURL(null); // Temporarily clear videoURL to force re-render

            if (type === 'movie') {
                const movies = await FetchMovies();
                const selectedMovie = movies.find((movie) => movie.id === id);
                setContent(selectedMovie);
                setVideoURL(selectedMovie?.videoURL);
            } else if (type === 'series') {
                const series = await FetchSeries();
                const selectedSeries = series.find((serie) => serie.id === id);
                setContent(selectedSeries);

                if (selectedSeries && selectedSeries.episodes && selectedSeries.episodes[episodeIndex]) {
                    setVideoURL(selectedSeries.episodes[episodeIndex].videoURL);
                }
            }
        };

        fetchContent();
    }, [id, episodeIndex, type]); // Include episodeIndex in dependencies to re-trigger on index change

    if (!content) return <p>Loading...</p>;

    return (
        <div className='watch'>
            <div className="back-btn">
                <Link to={`/detail/${type}/${id}`}><button>Back to Detail</button></Link>
            </div>
            <div className="watch-cnt">
                <br />
                {videoURL ? (
                    <video
                        id='vid'
                        controls
                        controlsList="nodownload noremoteplayback"
                        className="watch-video"
                        autoPlay
                        autoFocus
                    >
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <p>Loading video...</p>
                )}
            </div>
            <br /><br />
            <div className="back-btn">

                {episodeIndex > 0 && (
                    <Link to={`/seriesstream/series/${id}/${episodeIndex - 1}`}>
                        <button type='button'>Prev Ep : {episodeIndex}</button>
                    </Link>
                )}
                {content.episodes && episodeIndex < content.episodes.length - 1 && (
                    <Link to={`/seriesstream/series/${id}/${episodeIndex + 1}`}>
                        <button type='button'>Next Ep : {episodeIndex + 2}</button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default SeriesStream;
