import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './MainPages/home/Home'
import './style.css';
import NavBar from "./Navigation/NavBar";
import Anime from './Components/anime/Anime'
import Manga from './Components/manga/Manga'
import Special from "./Components/special/Special";
import Detail from "./MainPages/detail/Detail";
import Watch from './MainPages/stream/Stream'
import SeriesStream from "./MainPages/stream/SeriesStream";
import AdminLogin from "./AdminAccess/AdminLogin";
import UploadFiles from "./AdminAccess/UploadFiles";



function App() {
  return (
    <div className="app">

      <div className="bg-fixed-img">
        <div className="margin">
          <div className="bg-fixed-base">
            <div className="contents">

              <BrowserRouter>

                <NavBar />
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route path="/anime" element={<Anime />} />
                  <Route path="/manga" element={<Manga />} />
                  <Route path="/special" element={< Special />} />

                  <Route path="/detail/:type/:id" element={<Detail />} />
                  <Route path="/watch/:type/:id" element={<Watch />} />
                  <Route path="/seriesstream/:type/:id/:epid" element={<SeriesStream />} />

                  <Route path="/login" element={<AdminLogin />} />
                  <Route path="/uploadfiles" element={<UploadFiles />} />

                     {/* <Route path="/" element={< />} /> */}

                  


                </Routes>


              </BrowserRouter>
            </div>
          </div></div>
      </div>

    </div>
  );
}

export default App;
