
// FirebaseSeries.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig2 = {
    apiKey: "AIzaSyDVq3Ud-KGIvgpuenWS9ewYqc17aSPWiJs",
    authDomain: "anime1series.firebaseapp.com",
    projectId: "anime1series",
    storageBucket: "anime1series.appspot.com",
    messagingSenderId: "746440175265",
    appId: "1:746440175265:web:2b627c6130f5b4340e4ae0"
};

const app2 = initializeApp(firebaseConfig2, "app2");
const db2 = getFirestore(app2);
const storage2 = getStorage(app2);

export { db2, storage2 };
