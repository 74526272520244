import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import FetchMovies from '../../fetching/FetchMovie';
import FetchSeries from '../../fetching/FetchSeries';
import './Detail.css';

const Detail = () => {
  const { id, type } = useParams();
  const [isMovie, setIsMovie] = useState(null);
  const [movie, setMovie] = useState(null);
  const [firstEp, setFirstEP] = useState(null);
  const [allEpisodes, setAllEpisodes] = useState([]);

  useEffect(() => {
    if (type === 'movie') {
      setIsMovie(true);
      const fetchMovieDetails = async () => {
        const movies = await FetchMovies(); 
        const selectedMovie = movies.find((movie) => movie.id === id); 
        setMovie(selectedMovie);
      };

      fetchMovieDetails();
    } else if (type === 'series') {
      setIsMovie(false);
      const fetchSeriesDetails = async () => {
        const seriesList = await FetchSeries(); 
        const selectedSeries = seriesList.find((series) => series.id === id);
        setMovie(selectedSeries);
        if (selectedSeries?.episodes?.length > 0) {
          setAllEpisodes(selectedSeries.episodes);
          setFirstEP(0);
        }
      };

      fetchSeriesDetails();
    }
  }, [id, type]);

  if (!movie) return <p>Loading...</p>;

  return (
    <div className="detail-body">
      {isMovie ? (
        <div className="detail movie">
          <div className="img">
            <img src={movie.imageURL} alt={movie.titleEng} />
          </div>
          <div className="wrt-cont-mg">
            <div className="title-wrt">
              <h1>{movie.titleEng}</h1>
              <h2>{movie.titleEngJap}</h2>
            </div>
            <div className="des-wrt">
              <div className="hid">
                <p>{movie.description}</p>
              </div>
              <div className="ant-wrttt">
                <div className="ant1 aat">
                  <p>Movie</p>
                </div>
                <div className="ant2 aat">
                  <p>HD</p>
                </div>
                <div className="ant3 aat">
                  <span>2023</span>
                </div>
              </div>
            </div>
            <div className="btns-wrt">
              <button type="button">
                <Link to={`/watch/${type}/${movie.id}`}>Watch Now</Link>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="series-dl">
          <div className="detail movie">
            <div className="img">
              <img src={movie.imageURL} alt={movie.titleEng} />
            </div>
            <div className="wrt-cont-mg">
              <div className="title-wrt">
                <h1>{movie.titleEng}</h1>
                <h2>{movie.titleEngJap}</h2>
              </div>
              <div className="des-wrt">
                <div className="hid">
                  <p>{movie.description}</p>
                </div>
                <div className="ant-wrttt">
                  <div className="ant1 aat">
                    <p>PG - <span>{allEpisodes.length}</span></p>
                  </div>
                  <div className="ant2 aat">
                    <p>HD</p>
                  </div>
                  <div className="ant3 aat">
                    <span>2023</span>
                  </div>
                </div>
              </div>
              <div className="btns-wrt">
                <button type="button">
                  <Link to={`/seriesstream/${type}/${movie.id}/${firstEp}`}>Watch Now</Link>
                </button>
              </div>
            </div>
          </div>
          <div className="episodes-list">
            <ul>
              {allEpisodes.map((item, index) => (

                <Link key={item.id} to={`/seriesstream/${type}/${movie.id}/${index}`}>
                  Ep {item.episodeNumber}
                </Link>

              ))}
            </ul>
          </div>

        </div>
      )}
    </div>
  );
};

export default Detail;
