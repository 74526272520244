// FirebaseMovie1.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig1 = {
    apiKey: "AIzaSyAnmrQ4BxpjbwEUZ0lVuCC5bY410mtqnAk",
    authDomain: "animemovie1-538d0.firebaseapp.com",
    projectId: "animemovie1-538d0",
    storageBucket: "animemovie1-538d0.appspot.com",
    messagingSenderId: "428556475792",
    appId: "1:428556475792:web:678ff5dc744fe72f3fa715"
};

const app1 = initializeApp(firebaseConfig1, "app1");
const db1 = getFirestore(app1);
const storage1 = getStorage(app1);

export { db1, storage1 };
