import React, { useState } from 'react';
import { auth } from '../Database/Manga1';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './Styy.css'

const AdminLogin = () => {
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState('');

    const nav = useNavigate();

    const LoginHandle = (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, pass)
            .then(cred => {
                const twoHoursInMs = 2 * 60 * 60; // 2 hours in seconds
                Cookies.set('acsTkn', cred.user.accessToken, { expires: twoHoursInMs / (24 * 60) });
                Cookies.set('_tokenResponse', JSON.stringify(cred._tokenResponse), { expires: twoHoursInMs / (24 * 60) });
                Cookies.set('userUid', cred.user.uid, { expires: twoHoursInMs / (24 * 60) });
                Cookies.set('userEmail', cred.user.email, { expires: twoHoursInMs / (24 * 60) });

                nav(window.history.back()); // Optionally navigate to the homepage
            })
            .catch(err => {
                alert("try again");
                console.error(err);
            });
    };



    return (
        <div className='login'>

            <form onSubmit={LoginHandle}>
                <h1>Admin Login</h1>
                <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                <input type="password" placeholder='Pass' value={pass} onChange={(e) => setPass(e.target.value)} required />
                <input type="submit" value={'Log In'} />
            </form>

        </div>
    )
}

export default AdminLogin